import React from 'react'
import { Link } from 'gatsby'

import NavBar from '../components/nav'
import Footer from '../components/footer'
import SEO from '../components/seo'
//import catAndHumanIllustration from '../images/cat-and-human-illustration.svg'
import screenShot from '../images/screenshot-top-fade2.png'
import appStore from '../images/app-store-badge.png'
import playStore from '../images/play-store-badge.png'

export default () => (
    <div>
        <SEO
            title="Grades in your Pocket"
            keywords={[
                `aspen`,
                `aspen grades`,
                `chicago`,
                `cps`,
                `chicago public schools`,
                `public schools in the district of columbia`,
                `public schools in washington, dc`,
                `dcps`,
                `grades`,
                `grade corner`,
            ]}
        />
        <body className="leading-normal tracking-normal text-white gradient">
            <NavBar />
            {/* Hero */}
            <div className="pt-4">
                <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center pb-6">
                    <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
                        <p className="uppercase tracking-loose w-full">Grades in your Pocket</p>
                        <h1 className="my-4 text-5xl font-bold leading-tight w-full">Grade Corner</h1>
                        <p className="leading-normal text-2xl mb-8">
                            Grade Corner is a mobile app that lets you view your Aspen&trade; School Grades.
                        </p>

                        <div className="flex w-full md:w-4/5 justify-center">
                            <a
                                className="ml-3 w-full"
                                href="https://itunes.apple.com/us/app/grade-corner/id1441670302?ls=1&mt=8"
                            >
                                <img src={appStore} alt="Apple Store Logo"></img>
                            </a>
                            <a
                                className="mx-3 w-full"
                                href="https://play.google.com/store/apps/details?id=com.romandc.gradecorner&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                            >
                                <img src={playStore} alt="Google Play Store Logo"></img>
                            </a>
                        </div>
                    </div>

                    <div className="w-full md:w-3/5 py-6 text-center flex justify-center relative">
                        <img className="w-2/3 md:w-2/3 z-50" src={screenShot} alt="App Screenshot" />
                    </div>
                </div>
            </div>
            {/* Waves SVG  */}
            <div className="relative -mt-12 lg:-mt-24">
                <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <path
                                d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                                opacity="0.100000001"
                            ></path>
                            <path
                                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                opacity="0.100000001"
                            ></path>
                            <path
                                d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                                id="Path-4"
                                opacity="0.200000003"
                            ></path>
                        </g>
                        <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
                        </g>
                    </g>
                </svg>
            </div>

            {/* Features */}
            <section className="bg-white py-8">
                <div className="container max-w-5xl mx-auto m-8">
                    <div className="flex flex-wrap">
                        <div className="w-5/6 sm:w-1/2 p-6">
                            <h3 className="text-3xl text-gray-800 font-bold leading-none mb-5">
                                Easy access to your grades: Anytime!
                            </h3>
                            <ul className="text-xl ml-5 text-gray-800 mb-3 list-disc">
                                <li>Supports many school systems that use the Aspen&trade; grading system</li>
                                <li>Supports families with multiple children</li>
                                <li>Shows assignment due dates and teacher remarks</li>
                                <li>Shows recently graded assignments</li>
                                <li>Fast and Secure</li>
                            </ul>
                            <div className="flex mt-auto bg-white rounded-b rounded-t-none overflow-hidden  p-6">
                                <div className="flex items-center justify-end">
                                    <Link to="/districts/">
                                        <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                                            See all Supported School Districts
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 hidden sm:block p-6">
                            <svg
                                class="w-full sm:h-64 mx-auto"
                                id="e4e5ac1e-f7c3-49db-bea6-73c2d2e1dfe0"
                                data-name="Layer 1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="888"
                                height="580.72337"
                                viewBox="0 0 888 580.72337"
                            >
                                <title>mobile_development</title>
                                <path
                                    d="M304.55839,539.72318s10.80448,17.13628,6.94581,21.776,28.94959,4.75576,28.94959,4.75576-4.92815-24.9281-3.4777-30.665Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#ffb8b8"
                                />
                                <circle cx="165.82548" cy="352.4157" r="33.87953" fill="#2f2e41" />
                                <polygon
                                    points="245.51 536.052 245.51 556.137 265.594 551.952 263.921 537.726 245.51 536.052"
                                    fill="#ffb8b8"
                                />
                                <path
                                    d="M472.64248,699.03783l56.06912,9.20538-14.2265,20.92131s-39.33207-13.38964-49.3743-13.38964S472.64248,699.03783,472.64248,699.03783Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#ffb8b8"
                                />
                                <path
                                    d="M345.44089,658.86891s41.84263-48.53745,55.23227-46.02689,19.24761,17.5739,19.24761,17.5739l7.53167,74.47988s-26.77928,2.51055-26.77928,0-5.02112-56.06912-5.02112-56.06912S356.32,735.85934,335.39866,678.11652Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#d0cde1"
                                />
                                <path
                                    d="M277.65584,691.50616s.83685,43.84262,25.94243,46.35318,52.7217-18.737,52.7217-18.737l45.19-21.64944s53.55856,28.34426,69.45876,20.81259c0,0,16.73705-17.57391,5.858-22.595s-.83685,0-.83685,0l-64.43765-33.4741s-12.55279-3.34741-34.31095,5.858-51.048,13.38964-51.048,13.38964Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#d0cde1"
                                />
                                <circle cx="163.49846" cy="363.66048" r="25.10558" fill="#ffb8b8" />
                                <path
                                    d="M355.48312,593.59441l-1.6737,35.14781s.83685,46.02689-6.69482,49.3743c-3.80767,1.6904-11.45652,5.088-18.52793,8.80365-5.30562,2.77836-10.2849,5.72409-13.06326,8.25978a9.36818,9.36818,0,0,0-1.88291,2.18417c-3.34741,5.858-25.10558,5.02112-30.1267.83686s-1.6737,5.02111-5.858,6.69482c-4.05039,1.62349-9.66564-11.649-6.22619-31.46569.1088-.66109.23435-1.33057.36822-2.00841,3.84117-19.20578-2.19255-65.20759-1.26366-82.98233a31.2726,31.2726,0,0,1,.42681-4.05034c2.51055-13.38964,34.54529-40.63755,40.53714-29.03041,7.381,14.32691,24.33568,1.23852,27.24791-5.28054,16.73705-.83685,22.595,19.24761,22.595,19.24761Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#6c63ff"
                                />
                                <path
                                    d="M423.26818,708.24321s-13.38964,5.02111-17.57391,4.18426-8.36852,2.51056-8.36852,7.53167-5.02112,16.737,2.51056,17.57391,38.49521-.83686,45.19,1.6737c0,0,30.12669.83685,27.61614-11.71593s-15.06335-11.71594-15.06335-11.71594S423.26818,714.10117,423.26818,708.24321Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#2f2e41"
                                />
                                <path
                                    d="M521.17992,710.75376s1.67371-5.02111,4.18427-5.02111,22.595,3.34741,25.10557,2.51056,31.8004-2.51056,26.77928,11.71593-21.75816,15.9002-21.75816,15.9002-30.12669-3.34741-34.311-2.51056-19.24761,0-16.73705-4.18426S522.85363,717.44858,521.17992,710.75376Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#2f2e41"
                                />
                                <path
                                    d="M445.00463,590.55116l4.59111,18.93835a1.18275,1.18275,0,0,1-.023.6392L428.7465,675.21078a1.18288,1.18288,0,0,1-2.30193-.22695l-2.88107-25.35342a1.18282,1.18282,0,0,1,.05063-.5l19.11622-58.667A1.18289,1.18289,0,0,1,445.00463,590.55116Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#3f3d56"
                                />
                                <polygon
                                    points="287.316 432.512 291.555 448.761 273.186 508.105 268.947 489.737 287.316 432.512"
                                    fill="#fff"
                                />
                                <path
                                    d="M350.76762,680.45975c.70648,2.11943,73.47331-.70647,74.17979-1.41294a15.33535,15.33535,0,0,0,1.55421-2.8259c.66413-1.413,1.27169-2.8259,1.27169-2.8259l-2.8259-22.87563L352.887,647.96194s-1.80154,19.38566-2.16892,28.259A21.19873,21.19873,0,0,0,350.76762,680.45975Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#3f3d56"
                                />
                                <polygon
                                    points="259.763 493.975 261.176 513.757 229.385 513.757 229.385 493.975 259.763 493.975"
                                    opacity="0.1"
                                />
                                <polygon
                                    points="218.788 500.334 218.978 500.296 218.081 508.811 200.419 508.811 200.419 500.334 218.788 500.334"
                                    opacity="0.1"
                                />
                                <path
                                    d="M350.76762,680.45975c.70648,2.11943,73.47331-.70647,74.17979-1.41294a15.33535,15.33535,0,0,0,1.55421-2.8259h-75.7835A21.19873,21.19873,0,0,0,350.76762,680.45975Z"
                                    transform="translate(-156 -159.63832)"
                                    opacity="0.1"
                                />
                                <circle cx="171.43866" cy="315.37883" r="9.44184" fill="#2f2e41" />
                                <path
                                    d="M324.59935,485.29456s-9.64664,16.04562-32.07831,22.0523-.18324,39.71527-.18324,39.71527-8.09034-12.25767,3.57888-25.23887S326.3647,499.96712,324.59935,485.29456Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#2f2e41"
                                />
                                <path
                                    d="M328.58667,686.92017c-2.067,3.90814-4.49389,7.96685-4.90394,9.6071-.27618,1.113-3.339.46864-8.15932-1.34732-9.66563-3.65709-26.41944-12.03394-42.05184-20.41084a13.20191,13.20191,0,0,1-2.04192-1.339c.1088-.66109.23435-1.33057.36822-2.00841,3.84117-19.20578-2.19255-65.20759-1.26366-82.98233l7.12163-2.37663,14.22649,13.38964v56.906s36.82151,20.08446,39.33207,21.75817C332.49481,678.97007,330.729,682.86146,328.58667,686.92017Z"
                                    transform="translate(-156 -159.63832)"
                                    opacity="0.1"
                                />
                                <path
                                    d="M359.66738,641.295l22.595,11.71594s13.38964-14.22649,21.75817-3.34741S379.75185,663.89,379.75185,663.89s-28.453-6.69482-29.28984-15.9002S359.66738,641.295,359.66738,641.295Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#ffb8b8"
                                />
                                <path
                                    d="M322.009,672.25855l13.38964,3.34741S350.462,657.1952,366.36221,663.89s-20.08447,20.08446-20.08447,20.08446-19.2476,10.04223-26.77928,1.67371S322.009,672.25855,322.009,672.25855Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#ffb8b8"
                                />
                                <path
                                    d="M351.29886,561.794c.83685.83686,10.04223,7.53168,10.04223,7.53168s2.51056,31.80039-.83685,38.49521-5.858,6.69482-3.34741,10.04223,5.858.83686,2.51055,6.69482-6.69482,5.02112-4.18426,7.53168,1.67371,3.34741,1.67371,3.34741l10.04223,8.36852s-6.16116,9.81321-13.95966,9.09087-14.49333-38.38071-14.49333-38.38071Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#6c63ff"
                                />
                                <path
                                    d="M277.65584,581.87847l-7.53168,2.51056s-20.08446,73.643,3.34741,86.19581,49.3743,25.10558,50.21116,21.75817S333.725,675.606,331.2144,673.93225s-39.33207-21.75816-39.33207-21.75816v-56.906Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#6c63ff"
                                />
                                <polygon
                                    points="132.953 452.785 131.28 492.954 175.633 513.876 134.627 492.954 132.953 452.785"
                                    opacity="0.1"
                                />
                                <path
                                    d="M934.39972,293.39833v65.68a2.29614,2.29614,0,0,1-2.29,2.29h-1.62989v342.15a35.702,35.702,0,0,1-35.70007,35.7H680.9898a35.69346,35.69346,0,0,1-35.69006-35.7v-330.15h-1.18005a1.6176,1.6176,0,0,1-1.62-1.61005V334.37837a1.61971,1.61971,0,0,1,1.62-1.62006h1.18005V318.67836h-1.19006a1.69647,1.69647,0,0,1-1.68994-1.7v-36.87a1.69432,1.69432,0,0,1,1.68994-1.69h1.19006v-19.22h-1.21008a1.498,1.498,0,0,1-1.5-1.5v-20.12a1.5066,1.5066,0,0,1,1.5-1.51h1.21008v-40.74a35.69136,35.69136,0,0,1,35.69006-35.69H894.77972a35.69991,35.69991,0,0,1,35.70007,35.69v95.78h1.62989A2.29609,2.29609,0,0,1,934.39972,293.39833Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#3f3d56"
                                />
                                <rect
                                    x="604.90335"
                                    y="18.3068"
                                    width="39.82343"
                                    height="8.08642"
                                    rx="2.54189"
                                    fill="#e6e8ec"
                                />
                                <circle cx="655.33477" cy="22.35" r="4.58665" fill="#e6e8ec" />
                                <path
                                    d="M913.38473,202.38814v504.09a22.65045,22.65045,0,0,1-22.65,22.64h-204.65a22.61062,22.61062,0,0,1-22.65-22.64v-504.09a22.64906,22.64906,0,0,1,22.65-22.65h30.63v3.93a18.65015,18.65015,0,0,0,18.65,18.65h104.08a18.6501,18.6501,0,0,0,18.65-18.65v-3.93h32.64A22.64266,22.64266,0,0,1,913.38473,202.38814Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#fff"
                                />
                                <rect x="528.82677" y="351.95881" width="208" height="27" fill="#e6e6e6" />
                                <rect x="528.82677" y="399.95881" width="208" height="27" fill="#e6e6e6" />
                                <rect x="528.82677" y="447.95881" width="208" height="27" fill="#e6e6e6" />
                                <circle cx="713.82677" cy="90.95881" r="20" fill="#6c63ff" />
                                <rect x="528.40973" y="157.95881" width="208" height="138" fill="#e6e6e6" />
                                <path
                                    d="M767.2354,395.188c6.07317,22.45006,26.87512,36.35394,26.87512,36.35394s10.95541-22.49485,4.88225-44.94491-26.87512-36.35394-26.87512-36.35394S761.16224,372.738,767.2354,395.188Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#3f3d56"
                                />
                                <path
                                    d="M776.15949,390.36329c16.66485,16.22255,18.70034,41.1604,18.70034,41.1604s-24.98357-1.36412-41.64842-17.58667-18.70033-41.1604-18.70033-41.1604S759.49464,374.14073,776.15949,390.36329Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#6c63ff"
                                />
                                <path
                                    d="M822.48425,395.188c-6.07317,22.45006-26.87512,36.35394-26.87512,36.35394s-10.95541-22.49485-4.88225-44.94491S817.602,350.24314,817.602,350.24314,828.55741,372.738,822.48425,395.188Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#3f3d56"
                                />
                                <path
                                    d="M813.56016,390.36329c-16.66484,16.22255-18.70033,41.1604-18.70033,41.1604s24.98356-1.36412,41.64841-17.58667,18.70033-41.1604,18.70033-41.1604S830.225,374.14073,813.56016,390.36329Z"
                                    transform="translate(-156 -159.63832)"
                                    fill="#6c63ff"
                                />
                                <rect y="578.48265" width="888" height="2.24072" fill="#3f3d56" />
                            </svg>
                        </div>
                    </div>
                </div>
            </section>

            {/* Made by */}
            <section className="bg-gray-100 py-8">
                <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
                    <h1 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
                        Inspired by Parents and Students
                    </h1>
                    <div className="w-full mb-4">
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>
                    <p className="text-xl ml-5 text-gray-800 mb-5">
                        Grade Corner was originally inspired by a frustrated family who had two children
                        attending public schools in Washington, DC. After many end-of-term arguments and
                        finger pointing, a prototype app was created to assist both children and parents.
                    </p>
                    <p className="text-xl ml-5 text-gray-800 mb-5">
                        After showing the app to a few family and friends, it quickly gained popularity in the
                        local middle school. And then started to grow city-wide.
                    </p>
                    <p className="text-xl ml-5 text-gray-800 mb-5">
                        Then one Christmas break, while visiting family in Massachusetts, we discovered that
                        nieces and nephews are also using Aspen. So the app was adapted for their school
                        district. Eventually, more and more school districts were added including Chicago
                        Public Schools and Boston Public Schools.
                    </p>
                    <p className="text-xl ml-5 text-gray-800 mb-5">
                        Join thousands of student across the country who use Grade Corner daily. Having grades
                        in your pocket encourages timely conversations with teachers about assignments to
                        assist the learning process.
                    </p>
                </div>
            </section>

            {/* Testimonials */}
            <section className="bg-white py-8">
                <div className="container mx-auto flex flex-wrap pt-4 pb-12">
                    <h3 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
                        User Reviews
                    </h3>
                    <div className="w-full mb-4">
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>

                    <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                            <div className="w-full font-bold text-xl text-gray-800 px-6">Must Have!!</div>
                            <p className="text-gray-800 text-base px-6 mb-5">
                                This app helps me check my grades quickly I just go on my phone and tap the
                                app instead of having to go to safari and logging in I just tap ! A must have.
                                🥺♥️
                            </p>
                        </div>
                    </div>

                    <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                            <div className="w-full font-bold text-xl text-gray-800 px-6">Works Great!</div>
                            <p className="text-gray-800 text-base px-6 mb-5">
                                It does what it is designed to do. I appreciate being able to check my child’s
                                grades on my phone. For more detailed information I know I must sign on to
                                Aspen.
                            </p>
                        </div>
                    </div>

                    <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                            <div className="w-full font-bold text-xl text-gray-800 px-6">
                                Grade corner is great
                            </div>
                            <p className="text-gray-800 text-base px-6 mb-5">
                                This is a good app and helped we look at my grades more efficiently
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Waves SVG */}
            <svg className="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-1.000000, -14.000000)" fill-rule="nonzero">
                        <g className="wave" fill="#ffffff">
                            <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                        </g>
                        <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                            <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                                <path
                                    d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                                    opacity="0.100000001"
                                ></path>
                                <path
                                    d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                    opacity="0.100000001"
                                ></path>
                                <path
                                    d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                                    opacity="0.200000003"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>

            {/* CTA block */}
            <section className="flex flex-col justify-center container mx-auto text-center py-6 mb-12">
                <h3 className="mt-4 mb-8 text-3xl leading-tight">Download Now For Free</h3>
                <div className="flex flex-row w-full">
                    <a
                        className=" ml-3 w-full"
                        href="https://itunes.apple.com/us/app/grade-corner/id1441670302?ls=1&mt=8"
                    >
                        <img className="mx-auto" src={appStore} alt="Apple Store Logo"></img>
                    </a>
                    <a
                        className=" mx-3 w-full"
                        href="https://play.google.com/store/apps/details?id=com.romandc.gradecorner&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    >
                        <img className="mx-auto" src={playStore} alt="Google Play Store Logo"></img>
                    </a>
                </div>
            </section>

            {/* Footer */}
            <Footer></Footer>
        </body>
    </div>
)
